<template>
  <Block title="About" gap="2">
      <p>
        I’m a front end developer and UX designer based in Sydney,
        Australia. I have a love for building things from scratch quickly,
        thinking through experiences end-to-end and all things product.
      </p>
      <p>
        I mainly work with Javascript, React, Vue, CSS, and Firebase.
      </p>
    </Block>
</template>

<script>
import Block from '../subcomponents/Block.vue';

export default {
  components: {
    Block,
  },
};
</script>

<template>
  <Block title="Resume" gap="2">
      <a download="Pat Mifsud Resume.pdf" href="/Pat Mifsud Resume.pdf">
        Download Resume
      </a>
    </Block>
</template>

<script>
import Block from '../subcomponents/Block.vue';

export default {
  components: {
    Block,
  },
};
</script>
